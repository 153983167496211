
@include media-breakpoint-up(xl) {
    .iq-float-menu-item, .iq-float-menu-open-button {
        margin-right: 4.688rem !important; 
        margin-left: unset;
    }
}

.iq-search-bar{
    .search-input{
        padding: .8125rem 3.125rem .8125rem 1.25rem;
    }
    .searchbox{
        .search-link {
            right: 0.938rem; 
            left: auto;
        }
    } 
} 
.iq-navbar-logo{
    a{
        span {
            margin-right: 0.625rem; 
            margin-left:unset;
        }
    }
}

.iq-top-navbar{
    .sub-drop {
        left: 0;
        right: auto;
    }
} 
.breadcrumb-item {
	& + .breadcrumb-item {
		&::before {
            float: right;
            padding-left: .5rem;
            padding-right: 0;
		}
	}
}

.iq-top-navbar{
    .iq-navbar-custom{
        .navbar.navbar-expand-lg{
            .navbar-collapse{
                .navbar-nav.ms-auto{
                    margin-left: unset !important;
                }
            }
        }
    }
}