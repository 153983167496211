.badges-icon {
    margin-top: -3.438rem;
    margin-bottom:1.25rem;
}
.badge {
    line-height: 1.3rem;
}
.btn {
    .badge {
        vertical-align: middle;
    }
}