/*===================
sharemodal
=======================*/
.sharemodal {
    .share-form {
        input {
            padding: 0.75rem 1rem 0.75rem 3.5rem;
        }

        .share-link-btn {
            right: auto;
            left: 1rem;
        }
    }
}