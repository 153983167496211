.datepicker-cell {
    &:not(.disabled):hover {
        background: $gray-300;
    }
    &.prev,
    &.next {
      &:not(.disabled) {
          font-weight: 500;
          color: $gray-500;
      }
    }
    &.selected {
        // box-shadow: $component-active-shadow;
        &:hover {
            background-color: var(--#{$variable-prefix}primary);
        }
    }
}
.datepicker-view{
    &.datepicker-grid {
        .datepicker-cell {
            height: inset;
        }
    }
}
.datepicker-grid {
    width: 100%;
}

.datepicker {
    .datepicker-picker {
        background-color: var(--#{$prefix}gray-900);
        .datepicker-controls {
            .button {
                background-color: var(--#{$prefix}gray-900);
                color: var(--#{$prefix}heading-color);
            }
        }
        .datepicker-cell.focused,
        .datepicker-cell:hover {
            background-color: var(--bs-primary-bg-subtle);
        }
    }
}

.datepicker-header {
    .datepicker-controls {
        .button {
            &:hover {
                background-color: var(--bs-light-bg-subtle) !important;
            }
        }
    }
}
