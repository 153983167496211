.modal {
	z-index: 1111;
}
.modal-open {
	overflow-x: hidden;
}

.suggestion-card {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	display: flex;
	gap: 2em;
}

.suggestion-card::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 0;
}

.suggestion-card::-webkit-scrollbar-track {
	background: transparent;

	@if $enable-rounded ==true {
		@include radius-mixin(20px);
	}
}

.suggestion-card::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}

.suggestion-card::-webkit-scrollbar-thumb {
	background: transparent;

	@if $enable-rounded ==true {
		@include radius-mixin(20px);
	}

	&:hover {

		@if $enable-rounded ==true {
			@include radius-mixin(20px);
		}

	}
}

.suggestion-card{
   &:hover{
	&::-webkit-scrollbar-thumb{
		background: var(--bs-secondary);
	}
	&::-webkit-scrollbar-track{
		background: var(--bs-border-color);
	}
   }
}

/*===================
likemodal
=======================*/
.likemodal {
	.modal-body {
		height: 280px;
	}
}

/*===================
sharemodal
=======================*/
.sharemodal {

	.modal-header,
	.modal-body {
		padding: 0;
		border: none;
	}

	.modal-content {
		padding: 1.5rem;
	}

	.share-form{
		position: relative;
		input{
			font-size: 16px;
			padding: 0.75rem 3.5rem 0.75rem 1rem;
			color: var(--bs-primary);
			background-color: var(--bs-gray-100);
		}

		.share-link-btn{
			color: var(--bs-heading-color);
			padding: 0;
			position: absolute;
			right: 1rem;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

/*=======================
search modal
===========================*/
.iq-top-navbar {
	.search-modal-custom{
		background: var(--bs-gray-900);
		width: 100%;
		min-width: 300px;
		position: absolute;
		height: auto;
		top: calc(100% + 1rem);
		border-radius: .393em;
		overflow-x: hidden;
		overflow-y: auto;
		transform: translateY(-30px);
		opacity: 0;
		visibility: hidden;
		box-shadow: var(--bs-box-shadow);
		transition: opacity .3s ease-in-out, visibility .3s ease-in-out, transform .3s ease-in-out;

		&.open{
		   opacity: 1;
		   visibility: visible;
		   transform: translateY(0);
		}
	}
}


@include media-breakpoint-up(sm) {
	.sharemodal {
		.modal-dialog{
			max-width: 540px;
		}
	}
}

@include media-breakpoint-up(lg) {
	.iq-top-navbar {
		.search-hover {
			@if $enable-transitions ==true {
				@include transition-mixin(all 0.3s ease-in-out);
			}

			&:hover {
				background-color: rgba(var(--#{$variable-prefix}primary-rgb), .2);
			}
		}
	}

}

@include media-breakpoint-down(lg) {
	.search-hover {
		@if $enable-transitions ==true {
			@include transition-mixin(all 0.3s ease-in-out);
		}

		&:hover {
			background-color: rgba(var(--#{$variable-prefix}primary-rgb), .2);
		}
	}

	.suggestion-card {
		display: block;

		.story {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid $border-color;
			padding: .5em;

			.story-profile {
				display: flex;
				align-items: center;
			}

			&:hover {
				background-color: rgba(var(--#{$variable-prefix}primary-rgb), .2);
			}

			&:last-child {
				border-bottom: 0;
			}
		}
	}

}

@include media-breakpoint-up(sm) {
	#post-modal-data {
		.modal {
			.modal-dialog {
				max-width: 46.875rem;
			}
		}
	}
}

@media (max-width:991px) {
	.iq-top-navbar {
		.search-modal-custom {
			overflow: hidden;
			width: 100%;
			left: 0;
			right: 0;
			top: 100%;
			max-width: 100%;
			display: block;
		}
	}
}