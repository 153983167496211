.card1 {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  background-color: white;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card1:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 1);
  transform: scale(1.05);
}

/* app.css */

.page-container {
  padding: 50px;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100vw;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
}

.card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: center;
  max-width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
    /* card-height: 300px; */ /* Commented out as it's not directly used */
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 15px;
    /* card-height: 200px; */ /* Commented out as it's not directly used */
  }
}

.card1 {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  background-color: white;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
    transform: scale(1.05);
  }
}

.image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
}

.text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.price {
  margin: 5px 0 10px 0;
  font-size: 20px;
  color: #b22222;
  text-align: center;
  line-height: 1.2;
  max-height: 2.4em;
  min-height: 2.4em;
}

.title {
  margin: 5px 0 10px 0;
  font-size: 16px;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 20px;
  color: #555;
}

.address {
  margin: 0 0 5px 0;
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  color: black;
}

.bedroom {
  font-size: 14px;
  color: #333;
}

.available-div {
  margin: 5px 0 0px 0;
  display: flex;
  justify-content: flex-end;
}

.available {
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.available span {
  font-size: 13px;
  font-weight: normal;
}

.description {
  margin: 0 0 5px 0;
  font-size: 14px;
  text-align: left;
  color: #333;
  font-weight: 480;
  margin-top: 10px;
}

.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.searchbar {
  padding: 15px 20px;
  width: 70%;
  height: 20px;
  border-radius: 25px;
  border: 1px solid #555;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* App.css */
.filter {
  display: flex;
  gap: 18px;
  margin-bottom: 25px;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  flex-direction: row; /* Default to row for larger screens */

  @media (max-width: 768px) {
    flex-direction: column; /* Change to column for smaller screens */
    gap: 14px;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }
}

.filter-item {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust the gap between label and input as needed */

  @media (max-width: 480px) {
    padding-left: 20px;
  }
}

.filter-button {
  padding: 8px 16px;
  width: 115px; /* Adjust the width as needed */
  border-radius: 10px; /* Higher value makes the button rounder */
  border: 1px solid #555; /* Dark gray border to match the search bar */
  outline: none; /* Remove default outline on focus */
  text-align: left;
}
