.social-info {
	p {
		text-align: center;
	}
}

.profile-detail {
	h3 {
		text-align: center;
	}
}
.forms-add{
	padding: 20px;
}
.news-icon {
	margin-left: 1.25rem;
	margin-right: unset;
}

.comment-activity {
	>a {
		margin-left: 0.625rem;
		margin-right: unset;
	}
}

form.comment-text {
	input {
		padding-left: 8.125rem;
		padding-right: .75rem;
	}
}

ul.header-nav {
	left: 0;
	right: auto;
}

.comment-attagement {
	right: auto;
	left: 0;
}

.offcanvas-header {
	.btn-close {
		margin: unset;
	}
}

ul.header-nav {
	li {
		a {
			margin: 0 0 0 0.938rem;
		}
	}
}

.event-post {
	h5 {
		text-align: center;
	}

	p {
		text-align: center;
	}
}

.inner-page-title {
	right: 1.875rem;
	left: unset;
}

a {
	&.image-edit-btn {
		right: auto;
		left: 0.938rem;
	}
}

.iq-profile-avatar:before {
	left: 0;
	right: unset;
}

.post-user-liked-list {
	li {
		margin-left: 0;
		margin-right: -13px;

		&:first-child {
			margin-right: 0;
		}
	}
}

.comment-list {
	.comment-list-block {
		.comment-list-user-comment {
			margin-left: 0;
			margin-right: 4rem;
		}
	}
}

.add-comment-form-block {
	.add-comment-form {
		.form-control {
			padding: $input-padding-y $input-padding-x $input-padding-y 7.5rem;
		}

		button {
			right: auto;
			left: 1rem;
		}
	}
}

.profile-status-online {
	&::before {
		right: auto;
		left: 0;
		margin-right: 0;
		margin-left: -1rem;
	}
}

.profile-status-offline {
	&::before {
		right: auto;
		left: 0;
		margin-right: 0;
		margin-left: -1rem;
	}
}

.icon-rtl {
	transform: scaleX(-1);
}

.user-meta{
	li{
		&::after{
			left: 0;
			right: auto;
		}
	}
}

.profile-center {
	.header-avatar{
		.change-profile-image{
			right: auto;
			left: -0.5rem;
		}
	}
} 

@include media-breakpoint-down(md){
	.comment-list {
		.comment-list-block {
			.comment-list-user-comment {
				margin-right: 3.5rem;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	ul.header-nav {
		li {
			margin: 0 0 0 0.625rem;
		}
	}
}