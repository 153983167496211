/*!
* Version: 5.2.0
* Template: SocialV - Responsive Bootstrap 5 Admin Dashboard Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for responsive Template.
*
*/

// Customizer
@import "./custom/customizer/layouts";
@import "./custom/globle";
@import "./custom/custom-class";