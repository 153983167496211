.select2-container--default {

  .select2-selection--multiple {
    background: var(--#{$prefix}gray-900);
    padding: .575rem .75rem;

    .select2-selection__choice {
      background-color: var(--#{$prefix}gray-300);
    }
  }

  .select2-selection__choice__display {
    color: var(--bs-secondary);
  }

  .select2-results__option--selected {
    background-color: var(--#{$prefix}gray-200);
  }

  .select2-results__option--selectable {
    background: var(--#{$prefix}gray-900);
  }
}

// .select2-container--default .select2-selection--multiple .select2-selection__choice